import { createApp } from "vue";
import App from "./App.vue";
import justom,{utils} from "justom-share";
import ElementPlus from "element-plus";
import router from "@/router";
import store from "@/store";
import composition from "./composition";
import httpInstance from "@/public/request";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import AppBackLink from "@/components/AppBackLink.vue";
import masonry from 'vue-next-masonry';
import 'element-plus/dist/index.css'

const app = createApp(App);
app.use(masonry)
// $http
app.config.globalProperties.$http = httpInstance;
app.config.globalProperties.$utils = utils;
// this.set
app.config.globalProperties.set = function (
  arr: Array<any>,
  idx: any,
  value: any
) {
  arr[idx] = value;
};

app.component("AppBackLink", AppBackLink);

// plugins
app.use(router);
app.use(store);
app.use(ElementPlus, { size: "small", locale: zhCn });
app.use(justom);

// 组件 组合/关联
composition({ app, router, store, httpInstance });

app.mount("#app");
